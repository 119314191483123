@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');

@layer base {
  body {
    background-color: #F8F5F3;
    margin: 0;
    @apply font-[Montserrat]
  }
}
